function resolveImageUrl(url) {
    if (!url) {
      return '';  
    }
  
    if (url.startsWith('http')) {
      return url;  
    }
  
    const cleanedUrl = url.startsWith('/') ? url.slice(1) : url;
  
    return `https://assets.tina.io/${process.env.TINACMS_CLIENTID || '2d67a244-9eb9-4695-b6b1-fc08ac919ddd'}/${cleanedUrl}`;
  }
  
  module.exports = { resolveImageUrl };
  